import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  sideMenuItems: SideMenuItem[] = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: 'home',
      link: '/dashboard'
    },
    {
      id: 'forms',
      title: 'Forms',
      icon: 'article',
      link: '/forms'
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: 'settings',
      link: '/settings',
      class: 'mt-auto'
    },
    {
      id: 'logout',
      title: 'Logout',
      icon: 'logout',
      command: () => {
        this.authService.logout();
      }
    }
  ];

  sideMenuExpanded = true;

  constructor(private authService: AuthService) {

  }

  toggleSideMenu() {
    this.sideMenuExpanded = !this.sideMenuExpanded;
  }
}

interface SideMenuItem {
  id: string;
  title: string;
  icon: string;
  link?: string;
  command?: () => void;
  class?: string;
}
