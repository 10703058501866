import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@layout/layout.module';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { SkeletonModule } from 'primeng/skeleton';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    LayoutModule,
    CoreModule.forRoot(),
    SkeletonModule,
    ToastModule,
    ConfirmDialogModule
  ],
  providers: [
    provideClientHydration(),
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
