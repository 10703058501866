import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class HttpBaseUrlInterceptor implements HttpInterceptor {
  private static readonly authUrlPaths = [
    '/realms/*',
  ];

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('http')) {
      const isAuthUrl = HttpBaseUrlInterceptor.authUrlPaths
        .map(path => !!req.url.match(path))
        .reduce((p1, p2) => p1 || p2);
      let baseUrl;
      if (isAuthUrl) {
        baseUrl = environment.idpBaseUrl;
      } else {
        baseUrl = environment.apiBaseUrl;
      }
      let path = req.url;
      if (req.url.startsWith('/')) {
        path = req.url.substr(1);
      }
      const apiReq = req.clone({ url: `${baseUrl}/${path}` });
      return next.handle(apiReq);
    }
    return next.handle(req);
  }
}
