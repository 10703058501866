import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpFieldExcluderInterceptor implements HttpInterceptor {
  private _fieldsToExclude = ['createdDate', 'lastModifiedDate', 'createdBy', 'lastModifiedBy'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.clean(req.body);
    return next.handle(req);
  }

  clean(body: any) {
    if (body === null || body === undefined ) {
      return;
    }
    if (typeof body !== 'object' ){
      return;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this._fieldsToExclude.indexOf(key) !== -1) {
        delete body[key];
      } else if (typeof value === 'object') {
        this.clean(value);
      }
    }
  }
}
