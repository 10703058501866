import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { User } from '@core/data/model/user';
import { Page } from '@core/data/model/page';
import { Member } from '@core/data/model/member';

@Injectable()
export class UserService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('user-service/users', httpClient);
  }

  currentUser(): Observable<User> {
    return this.get<User>( '');
  }

  findAllMembers(page = 30, size = 0): Observable<Page<Member>> {

    return this.get<Page<Member>>(`/members?page=${page}&size=${size}`);
  }

  createMember(member: Member): Observable<Member> {
    return this.post<Member>( '/members', member);
  }

  updateMember(member: Member): Observable<Member> {
    if (!member.id) {
      throw new Error('ID is required in order to update a resource.');
    }
    return this.put<Member>( '/members', member);
  }
}
