import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@env';

export const authConfig = (_window: Window): AuthConfig => {
  return {
    issuer: `${environment.idpBaseUrl}/realms/qreview`,
    redirectUri: `${_window.location.protocol}//${_window.location.host}`,
    clientId: 'qreview-app',
    responseType: 'code',
    scope: 'openid profile email',
    silentRefreshRedirectUri: `${_window.location.protocol}//${_window.location.host}/auth-silent-refresh.html`,
    useSilentRefresh: true,
    showDebugInformation: !environment.prod,
    sessionChecksEnabled: false,
    timeoutFactor: 0.75,
    checkOrigin: true,
    preserveRequestedRoute: true,
    clearHashAfterLogin: false,
    requireHttps: environment.prod
  }
};
