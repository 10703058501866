<div class="grid grid-cols-48 bg-white">
  <aside class="side-menu"
         [ngClass]="{'collapsed': !sideMenuExpanded}">
    <div class="mb-8 pl-3.5 pt-3.5 h-[65px] flex items-center justify-between">
      <img alt="logo" class="h-full" [src]="sideMenuExpanded ? 'assets/img/logo.png' : 'assets/img/logo_icon.png'"/>
      <div class="cursor-pointer flex absolute -right-[13px] rounded-full" (click)="toggleSideMenu()">
        <mat-icon [class]="{ 'rotate-180': !sideMenuExpanded }">menu_open</mat-icon>
      </div>
    </div>

    <div class="flex flex-col px-3.5 grow">
      @for (sideMenuItem of sideMenuItems; track sideMenuItem.id) {
        @if (sideMenuItem.link) {
          <div
            class="h-[50px] mb-1 cursor-pointer px-3 rounded-md gap-3 hover:bg-gray-100"
            [ngClass]="[sideMenuItem.class ? sideMenuItem.class : '']" [class.active-menu-link]="rla.isActive">
            <a routerLink="{{ sideMenuItem.link }}"
               routerLinkActive="active"
               [class.justify-center]="!sideMenuExpanded"
               class="flex h-full w-full items-center space-x-2 text-gray-800"
               #rla="routerLinkActive">
              <mat-icon>{{ sideMenuItem.icon }}</mat-icon>
              @if (sideMenuExpanded) {
                <span>{{ sideMenuItem.title }}</span>
              }
            </a>
          </div>
        } @else if (sideMenuItem.command) {
          <div
            class="h-[50px] mb-1 cursor-pointer px-3 rounded-md gap-3 hover:bg-gray-100"
            [ngClass]="[sideMenuItem.class ? sideMenuItem.class : '']" (click)="sideMenuItem.command()">
            <label [class.justify-center]="!sideMenuExpanded"
               class="flex h-full w-full items-center space-x-2 text-gray-800 cursor-pointer">
              <mat-icon>{{ sideMenuItem.icon }}</mat-icon>
              @if (sideMenuExpanded) {
                <span>{{ sideMenuItem.title }}</span>
              }
            </label>
          </div>
        }
      }
    </div>

    <div class="py-4 px-5 h-[65px] border-t border-solid border-gray-200">
      @if (sideMenuExpanded) {
        <img alt="logo" class="h-full" src="assets/img/logo.png"/>
      }
    </div>
  </aside>
  <main class="z-0" [ngClass]="{'col-span-40': sideMenuExpanded, 'col-span-46': !sideMenuExpanded}">
    <app-page-header class="sticky top-0" />
    <div class="px-10 py-6">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

