import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Message, MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * This service is for surfacing errors in the UI. By default, we'll notify the user with a snackbar (toast) message.
 * You should only need to include this service if you need to surface errors in the ui or need to notify the user
 * of errors.
 */
@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler extends ErrorHandler {
  public errorEvent: Subject<Error> = new Subject<any>();

  constructor(
    private log: NGXLogger,
    private messageService: MessageService
  ) {
    super();
  }

  /**
   * Create a snackbar notification
   * @param {string} notification - The notification message
   * @param {number} notificationCode - An error code or Http Status
   */
  notifyUserSnackbar(notification: string, notificationCode?: number) {
    const message: Message = {
      severity: 'error',
      summary:  'Ouch!',
      detail: `${ notificationCode ? notificationCode + ': ' : '' }${ notification }`
    };

    this.messageService.add(message);
  }

  /**
   * This function is a global javascript error handler. It will catch all javascript errors
   * produced within the application. The docs at https://angular.io/api/core/ErrorHandler say
   * that err has a type of any. I'm assuming the "any" type is so you can pass it a custom error
   * or error event. console logging the err argument just outputs the stack trace.
   *
   * If an error occurs within a try/catch block, this will not be fired unless you
   * throw the error within the catch block.
   *
   * I understand I've got repeated code below, but this is merely a placeholder so that
   * custom logic can be introduced based on the error type (err.name). You probably don't
   * want to break it down to that level but instead send to a DB, throw a dialog
   * for debugging, etc.
   *
   * Definition is in @see{src/app/core/core.module.ts}
   *
   * @param err {Error}
   */
  override handleError(err: Error) {
    let message = err?.message || 'An error occurred. Please try again or ask support for help!';
    if (!message && 'error' in err && 'message' in (err.error as Object)) {
      message = (err.error as { message?: string })['message'] || 'An error occurred. Please try again or ask support for help!';
    }

    this.log.error(message, err);
    this.errorEvent.next(err);
  }
}
