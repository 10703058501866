import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse, HttpErrorResponse, HttpInterceptor
} from '@angular/common/http';
import { HttpCacheService } from '@core/services/http/http-cache.service';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

/**
 * Intercept all http requests
 * @class {HttpCacheInterceptor}
 */
@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {

  constructor(
    private cache: HttpCacheService,
    private log: NGXLogger
  ) {
  }

  /**
   * When an http request starts, set loading to true. When the request is finished, set loading to false.
   * If an error is thrown be sure loading is set to false.
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //TODO: Handle global loading - this.loading.setLoading(true, request);
    const cacheable = this.cache.shouldCache(request);
    let cachedResponse: HttpEvent<any>;
    if (cacheable) {
      cachedResponse = this.cache.get(request);
      if (cachedResponse) {
        this.log.debug(`[HttpRequestInterceptor.intercept] Response from cache for ${ request.urlWithParams }`, cachedResponse);
        //TODO: Handle global loading - this.loading.setLoading(false, request);
        // Just return cached response and DO NOT make a network request
        return of<HttpEvent<any>>(cachedResponse);
      }
    }
    return next.handle(request)
      .pipe(
        tap<HttpEvent<any>>((httpEvent: HttpEvent<any>) => {
          if (httpEvent instanceof HttpResponse && cacheable) {
            this.cache.put(request, httpEvent);
          }
          return httpEvent;
        }),
        catchError((err: HttpErrorResponse) => {
          throw err;
        }),
        finalize(() => {
          //TODO: Handle global loading - this.loading.setLoading(false, request);
        })
      );
  }
}

