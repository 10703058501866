import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '@env';

const ALLOWED_URLS = [environment.apiBaseUrl, environment.idpBaseUrl];

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    sendAccessToken: true,
    customUrlValidation: (url) => {
      return !!ALLOWED_URLS.find(u => url.toLowerCase().startsWith(u.toLowerCase()))
        || url.startsWith('/');
    }
  }
};
