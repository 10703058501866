import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@env';
import { INTEGRATION_ROUTES } from '@modules/settings/integrations/integrations-routing.module';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { preload: true }
  },
  {
    path: 'settings',
    loadChildren: () => import('@modules/settings/settings.module').then(m => m.SettingsModule),
    data: { preload: true }
  },
  ...INTEGRATION_ROUTES,
  //{ path: '**', component: PageNotFoundComponent, title: '404 - Page Not Found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
