import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppErrorHandler } from '@core/services/error-handler/error-handler.service';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: AppErrorHandler,
    private authService: AuthService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err && err instanceof HttpErrorResponse && err.status === 401) {
          this.authService.logout(false);
          location.reload();
          return throwError(() => err);
        }

        let unknownErrorMessage = 'An error occurred. Please try again or ask support for help!';
        if (err.error) {
          let message = unknownErrorMessage;
          if (err.error.message) {
            message = err.error.message;
          } else if (err.error.developerMessage) {
            message = err.error.developerMessage;
          } else if (err.error.error) {
            message = err.error.error;
          }
          this.errorHandler.notifyUserSnackbar(message);
        } else if (!(err instanceof HttpErrorResponse && err.status === 401)) {
          this.errorHandler.notifyUserSnackbar(unknownErrorMessage);
        }

        return throwError(() => err);
      })
    );
  }
}
